import React from 'react'

function PartnerCard({
	name,
	bio,
	imageUrl,
	year,
	badgeUrl,
	imgAlt,
	badgeAlt,
}) {
	return (
		<article className="bg-white rounded-2xl transition-shadow duration-300 shadow-lg hover:shadow-xl flex flex-col">
			<div
				className="aspect-w-10 aspect-h-7 bg-purple bg-cover bg-center rounded-t-2xl"
				aria-label={imgAlt}
				style={{
					backgroundImage: `url(${imageUrl})`,
					backgroundPosition: '10%',
				}}
			/>
			<div className="flex flex-col rounded-b-2xl px-5 py-6 text-left flex-grow">
				<div className="flex">
					<img
						src={badgeUrl}
						alt={badgeAlt}
						className="mr-[10px] block w-5 h-5 rounded-xl mt-1"
					/>
					<div className="text pb-3">
						<span className="text-xl font-bold">{name}</span>
					</div>
				</div>

				<span className="text-sm pb-2">{bio}</span>
				<span className="text-black-faded mt-auto">{year}</span>
			</div>
		</article>
	)
}

export default PartnerCard

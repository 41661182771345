import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layouts'
import Container from '../components/Container'
import PartnerCard from '../components/PartnerCard'
import { graphql } from 'gatsby'
import ButtonLink from '../components/ButtonLink'
import AmbassadorImage from '../images/ambassador-hero.png'

const Ambassador = ({ data }) => {
	return (
		<>
			<Helmet>
				<title>Classify — Ambassador </title>
				<meta
					name="description"
					content="The all-in-one cross platform app for students to get organized, easily. Start now to get your academic life in order - minimal setup required."
				/>
				<link
					rel="canonical"
					href="https://classify.org.uk/ambassador"
				/>

				<meta property="og:title" content="Classify — Ambassador" />
				<meta
					property="og:url"
					content="https://classify.org.uk/ambassador"
				/>
				<meta
					property="og:image"
					content="https://i.imgur.com/OrggRiX.jpg"
				/>
				<meta
					property="og:description"
					content="The all-in-one cross platform app for students to get organized, easily. Start now to get your academic life in order - minimal setup required."
				/>
				<meta property="og:type" content="website" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@classifyapp" />
				<meta
					property="twitter:title"
					content="Classify — Ambassador"
				/>
				<meta
					name="twitter:image"
					content="https://i.imgur.com/OrggRiX.jpg"
				/>
				<meta
					property="twitter:description"
					content="The all-in-one cross platform app for students to get organized, easily. Start now to get your academic life in order - minimal setup required."
				/>
			</Helmet>
			<Layout>
				<section id="ambassador" className="bg-ambassador">
					<Container no-vertical>
						<div className="grid gap-12 text-center md:px-5 py-12 lg:py-24">
							<article className="lg:px-20 grid gap-9 md:px-5">
								<img
									src={AmbassadorImage}
									className="max-w-[20rem] m-auto"
								/>
								<p
									className="text-xl text-white mx-auto"
									style={{
										maxWidth: '44ch',
										wordBreak: 'break-word',
									}}
								>
									The Ambassador programme is a growing
									community of Classify-ers across the world,
									spreading the skill of organisation to
									millions of students. ✨
								</p>
								<div>
									<ButtonLink
										anchor
										color="black"
										to="/apply"
									>
										Want to join? Apply now!
									</ButtonLink>
								</div>
							</article>

							<article className="grid sm:grid-cols-2 xl:grid-cols-3 gap-12">
								{data.allWpPartner.nodes.map(
									(partner, index) => (
										<PartnerCard
											name={partner.title}
											imgAlt={
												partner.partnersContent.image
													?.altText
											}
											badgeAlt={
												partner.partnersContent.icon
													?.altText
											}
											bio={partner.partnersContent.bio}
											// get just the year from the date string
											year={`Est. ${partner.partnersContent.year}`}
											imageUrl={
												partner.partnersContent.image
													?.sourceUrl
											}
											badgeUrl={
												partner.partnersContent.icon
													?.sourceUrl
											}
											key={index}
										/>
									)
								)}
							</article>
						</div>
					</Container>
				</section>
			</Layout>
		</>
	)
}

export const pageQuery = graphql`
	query {
		allWpPartner {
			nodes {
				partnersContent {
					bio
					icon {
						altText
						sourceUrl
					}
					image {
						altText
						mediaItemUrl
						sourceUrl
					}
					year
				}
				title
			}
		}
	}
`

export default Ambassador
